.game-intro{
    text-align: center;
}

.game-container{
    display: grid;
    grid-template-columns: 1fr 0.5fr;
}

.game-leftside{
    padding: 20px 30px 30px 30px;
    border-right: 7px dotted var(--secondary-color);
}

.game-rightside{
    padding: 20px;
}

.game-link{
    text-align: center;
    margin-top: 50px;
}

.game-client-review{
    margin: 50px 0;
}

.game-img-border{
    border: 5px solid black;
}

.game-back-btn .btn{
    margin: 50px auto;
}


@media only screen and (max-width: 1000px){
    .game-container{
        display: block;
    }
    .game-leftside{
        padding: 0;
        border-right: none;
    }
}