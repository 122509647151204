.home-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--whole-body-height);
}

.home-intro{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
}

.home-intro-btn .btn{
    font-size: var(--textsize-heading);
}

.home-mascot img{
    max-width: 600px;
}

@media only screen and (max-width: 1250px){
    .home-main{
        flex-direction: column;
        justify-content: center;
        flex-flow: column-reverse;
        gap: 50px;
        margin: 20px 0;
    }
    .home-intro{
        justify-content: flex-start;
    }
}