footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
    padding: 30px 100px;
    margin-top: 100px;
    border-top: 5px solid var(--secondary-color);
}

.footer-info{
    text-align: center;
}


@media only screen and (max-width: 1000px){
    footer {
        flex-direction: column;
        padding: 30px 0;
    }
}