.contact-intro{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 150px;
}

.contact-form h2{
    color: var(--text-secondary);
    text-align: center;
}
.contact-form{
    max-width: 550px;
}

.contact-form-control{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 20px 0;
}

.contact-form-control input[type=text],
.contact-form-control input[type=email], textarea{
    width: 100%;

    border: 2px Solid var(--secondary-color);
    border-radius: 10px;

    background-color: transparent;
    padding: 15px 10px;

    font-size: 1.2rem;
    color: var(--text-primary)
}

.contact-form-control textarea:focus{
    outline: none;
}

.contact-form-control input:focus{
    outline: none;
}

::placeholder{
    color: #6cd3ff48;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: var(--text-primary);
}


@media only screen and (max-width: 1300px){
    .contact-intro{
       display: flex;
       flex-direction: column;
       align-items: center;
       gap: 50px;
    } 
}