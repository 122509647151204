.blog-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
    margin: 50px 0;
}

.blog-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    padding: 20px;
    gap: 50px;
}

.blog-card img{
    max-width: 300px;
}

.blog-empty-main{
    text-align: center;
}