.social-links{
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    font-weight: bold;
    width:fit-content;
    gap: 50px;
}

.social-vertical{
    flex-direction: column;
    gap: 15px;
}

.social-center-align{
    justify-content: center;
    align-items: center;
}

.twitter{
    color: #1DA1F2;
}
.instagram{
    color: #E1306C;
}
.reddit{
    color: #FF5700;
}

@media only screen and (max-width: 1000px){
    .social-links {
        flex-direction: column;
        gap: 20px;
    }
}