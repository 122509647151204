.about-main{
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    justify-content: center;
    gap: 200px;
}

.about-coffee{
    max-width: 400px;
}

.about-info p {
    margin: 20px 0;
    line-height: 1.5;
}

.about-experience {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
}

.about-flag{
    padding-right: 15px;
    color: var(--secondary-color);
}

@media only screen and (max-width: 1300px){
    .about-main{
        gap: 50px;
    }
}
@media only screen and (max-width: 1000px){
    .about-main{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .about-coffee{
        max-width: 250px;
    }
}