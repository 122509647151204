.notfound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 50px;
}

.notfound h2{
    font-size: calc(var(--textsize-heading) * 1.5);
}

.notfound .btn{
    font-size: calc(var(--textsize-heading) * 1.5);
}